// import { Routes, Route } from "react-router-dom";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import "./components/utils/DataTablesUtils";
import Layout from "./components/layout/Layout.jsx";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import ErrorPage from "./components/ErrorPage.js";
//import Game from "./components/game/Game";
import Home from "./components/home/Home";
import Login from "./components/auth/Login";
import ChangePassword from "./components/auth/ChangePassword";

import Rules from "./components/access/Rules.jsx";
import Roles from "./components/access/Roles.jsx";
import SettingAccess from "./components/access/SettingAccess.jsx";
import SysUsers from "./components/access/SysUsers.jsx";
import SysUser from "./components/access/SysUser.jsx";
import LogLists from "./components/access/LogLists.jsx";

import LoginHistory from "./components/access/LoginHistory.jsx";
import MenuDevices from "./components/page/Menu_admin/Devices.jsx";
import MenuProtocols from "./components/page/Menu_admin/Protocols.jsx";

const App = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/reset_password/:token/:login" element={<ResetPassword />} />
      <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
        {/* <Route index element={<Home />} /> */}
        <Route index element={<MenuDevices />} />
        <Route path="changepassword" element={<ChangePassword />} errorElement={<ErrorPage />} />
        <Route path="rules" element={<Rules />} errorElement={<ErrorPage />} />
        <Route path="rules/:id/roles" element={<SettingAccess />} errorElement={<ErrorPage />} />
        <Route path="roles" element={<Roles />} errorElement={<ErrorPage />} />
        <Route path="roles/:id/rules" element={<SettingAccess />} errorElement={<ErrorPage />} />
        <Route
          path="roles/:id/sys_users"
          element={<SettingAccess />}
          errorElement={<ErrorPage />}
        />
        <Route path="sys_users" element={<SysUsers />} errorElement={<ErrorPage />} />
        <Route path="sys_users/:id" element={<SysUser />} errorElement={<ErrorPage />} />
        <Route path="log_lists" element={<LogLists />} errorElement={<ErrorPage />} />
        <Route path="loginhistory" element={<LoginHistory />} errorElement={<ErrorPage />} />

        <Route path="devices" element={<MenuDevices />} errorElement={<ErrorPage />} />
        <Route path="events" element={<MenuProtocols />} errorElement={<ErrorPage />} />

        {/* <Route path="game" element={<Game />} errorElement={<ErrorPage />} /> */}
        <Route path="*" element={<ErrorPage errorCode="404" />} />
      </Route>
    </>
  )
  // { basename: "/admin", }
);

export default App;
