import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $, { data } from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../utils/appUtils";
import TableDT from "../../UI/TableDT";
import moment from "moment";

const ROUTE_URL = "/dashboard/lastLogins";

function MenuProtocols(props) {
    const [serverData, setServerData] = useState(null);
    const { showErrorPage, messageBox } = useOutletContext();
    const navigate = useNavigate();

    const handleAddRow = () => {
        navigate("./create");
    };

    const handleAfterDTCreate = ({ DOM, Api }) => {
        dtTable.Api = Api;
        $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlDelete = ROUTE_URL + "/" + data.id;
            messageBox({
                show: true, message: `Вы действительно хотите удалить "${data.name}"?`, mbOk: (e) => {
                    e.preventDefault();
                    messageBox({ show: false, mbOk: null });
                    requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
                }
            });
        });
        $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlStr = "./" + data.id;
            navigate(urlStr);
        });

    }

    useEffect(() => {
        requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
    }, []);



    console.log('serverData', serverData);

    if (serverData === null)
        return <MainArea loading />;

    return (
        <MainArea>
            <MainAreaHeader caption="События" />
            <MainAreaBody containerClass="">
                {/* {
                    <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
                } */}
                <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
            </MainAreaBody>
        </MainArea>
    );
}

let dtTable = {
    Api: null,
      dtParams: { dat_start: moment().add(-1, 'months').format("YYYY-MM-DD"), dat_end: moment().format("YYYY-MM-DD"), tz: (new Date()).getTimezoneOffset() },

    getTableOptions: (crud) => {
        const dtOptions = {
            ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
            order: [[1, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL + "?track",  "data": () => { return dtTable.dtParams },

       },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [ 
                { data: "created_at", title: "Дата", render: DataTable.render.text(), },
                { data: "login", title: "Логин", render: function (data, type, row) {
            if (type === 'display')
              return '<a class="sys-user-info" href="#" data-user-id="' + row.user_id + '">' + DataTable.render.text().display(row.login) + "</a>";
            else
              return data;
          }, },
                { data: "ip_address", title: "IP", render: DataTable.render.text(), },
                // { data: "comment", title: "Браузер", render: DataTable.render.text(), },
            ],
        };
        // if ((crud & 1) === 0) {
        //     dtOptions.order = [[0, "asc"]];
        //     dtOptions.columns.splice(0, 1);
        //     dtOptions.dom = getDataTableDom("lfip", 1, 1);
        // }

        console.log('dtOptions', dtOptions);


        return dtOptions;
    }
};

export default MenuProtocols;
